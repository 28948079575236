import React from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import { Button } from 'react-bootstrap';
import './info.scss';

export function Info({ icon, title, subtitles, cta, linkTo }) {
  return (
    <div className="info">
      <div>
        {icon}
        <h2>
          <b>{title}</b>
        </h2>
        {subtitles.map((subtitle, i) => (
          <p key={i}>{subtitle}</p>
        ))}
        {cta && linkTo && (
          <>
            <br />
            <div className="button-wrapper">
              <Link to={linkTo}>
                <Button className="button" variant="primary" block>
                  {cta}
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
