import React, { forwardRef } from 'react';
import './common.scss';

export type SectionProps = {
  children: React.ReactNode;
  type?: 'primary' | 'secondary';
  disabled?: boolean;
};

export const Section = forwardRef<HTMLElement, SectionProps>(
  ({ disabled, type = 'primary', children }: SectionProps, ref) => (
    <section
      ref={ref}
      className={`co-section ${
        disabled ? 'co-section-disabled' : ''
      } co-section-${type}`}
    >
      {children}
    </section>
  )
);

export type SectionGroupProps = {
  children: React.ReactNode;
  small?: boolean;
  border?: boolean;
  background?: boolean;
};

// Use this to apply padding to content inside a section
export const SectionGroup = ({
  children,
  small,
  border,
  background,
}: SectionGroupProps) => (
  <div
    className={`co-section-group ${small ? 'small' : ''} ${
      border ? 'with-border' : ''
    } ${background ? 'with-background' : ''}`}
  >
    {children}
  </div>
);

export const Options = ({ children, direction = 'column' }) => (
  <div className={`co-options ${direction}`}>{children}</div>
);

export type ContainerProps = {
  children: React.ReactNode;
  bottomSection?: React.ReactNode;
  bottomSectionBorder?: boolean;
};

export const Container = ({
  children,
  bottomSection,
  bottomSectionBorder = true,
}: ContainerProps) => (
  <div className="co-option-content">
    {children}
    {bottomSection && (
      <div
        className={`co-option-content-bottom ${
          !bottomSectionBorder ? 'no-border' : ''
        }`}
      >
        {bottomSection}
      </div>
    )}
  </div>
);

export const Horizontal = ({ children }) => (
  <div className="co-horizontal">{children}</div>
);

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
