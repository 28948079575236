import React, {
  ChangeEventHandler,
  FocusEventHandler,
  MouseEventHandler,
} from 'react';
import { Container } from '.';
import './common.scss';

export type OptionProps = {
  children: React.ReactNode;
  isInvalid?: boolean;
  id?: string;
  multiple?: boolean;
  section?: string;
  disabled?: boolean;
  selected?: boolean;
  onClick?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLLabelElement>;
  onMouseDown?: MouseEventHandler<HTMLLabelElement>;
  onClickLabel?: MouseEventHandler<HTMLLabelElement>;
  bottomSection?: React.ReactChild;
  bottomSectionBorder?: boolean;
  dataTestId?: string;
  withCheckbox?: boolean;
};

export const Option = React.forwardRef<HTMLLabelElement, OptionProps>(
  (props, ref) => {
    return (
      <label
        ref={ref}
        className={`co-option ${props.withCheckbox ? 'checkbox' : ''} ${
          props.disabled ? 'disabled' : ''
        } ${props.isInvalid ? 'invalid' : ''}`}
        htmlFor={props.id}
        data-test-id={props.dataTestId}
        data-testid={props.dataTestId}
        onBlur={props.onBlur}
        onMouseDown={props.onMouseDown}
        onClick={props.onClickLabel}
      >
        <input
          className="co-option-input"
          type={props.multiple ? 'checkbox' : 'radio'}
          id={props.id}
          name={props.section}
          disabled={!!props.disabled}
          onChange={props.onClick}
          checked={props.onClick ? props.selected : undefined}
          defaultChecked={props.onClick ? undefined : props.selected}
        />
        <Container
          bottomSection={props.bottomSection}
          bottomSectionBorder={props.bottomSectionBorder}
        >
          {props.children}
        </Container>
      </label>
    );
  }
);
