import React, { ReactNode } from 'react';
import './common.scss';

export type SectionHeadlineProps = {
  spacing?: 'small' | 'medium' | 'large';
  children: React.ReactNode;
};

export const SectionHeadline = ({
  spacing = 'small',
  children,
}: SectionHeadlineProps) => (
  <h2 className={`co-section-headline spacing-${spacing}`}>{children}</h2>
);

export type SectionSubHeadlineProps = {
  children: React.ReactNode;
  spacing?: 'small' | 'medium' | 'large';
  bold?: boolean;
};

export const SectionSubheadline = ({
  bold,
  spacing = 'large',
  children,
}: SectionSubHeadlineProps) => (
  <h3
    className={`co-section-subheadline spacing-${spacing} ${
      bold ? 'bold' : ''
    }`}
  >
    {children}
  </h3>
);

export type ParagraphProps = {
  children: React.ReactNode;
  className?: string;
  isHeadline?: boolean;
  center?: boolean;
  dangle?: {
    content: string | ReactNode;
    color: DangleColor;
    boldMobile?: boolean;
  };
  noSpacing?: boolean;
  size?: 'small';
};

type DangleColor = 'blue' | 'purple' | 'gray' | 'dark-gray';

export const Paragraph = (props: ParagraphProps) => {
  const headlineCn = props.isHeadline ? 'headline' : '';
  const centerCn = props.center ? 'center' : '';
  const spacingCn = props.noSpacing ? 'no-spacing' : '';
  const sizeCn = props.size ? props.size : '';
  const boldMobileCn = props.dangle?.boldMobile ? 'bold-mobile' : '';

  return (
    <p
      className={`co-paragraph ${props.className} ${headlineCn} ${centerCn} ${spacingCn} ${sizeCn}`}
    >
      {props.children}
      {props.dangle && (
        <span className={`co-dangle ${props.dangle.color} ${boldMobileCn}`}>
          {props.dangle.content}
        </span>
      )}
    </p>
  );
};

export interface ParagraphHeadlineProps
  extends React.ParamHTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
}

export const ParagraphHeadline = (props: ParagraphHeadlineProps) => {
  const { children, className, ...rest } = props;
  return (
    <p className={`co-paragraph-headline ${className}`} {...rest}>
      {props.children}
    </p>
  );
};
