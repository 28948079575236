import React from 'react';
import { ConditionalWrapper, Info } from '../components/common';
import NoBikeMessageIcon from '../icons/no-bike-message.inline.svg';
import LayoutWithoutCheckout from '../components/layout/layoutwithoutcheckout';
import Seo from '../components/layout/seo';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

const NotFoundPage = ({ withLayout = true }) => (
  <>
    <ConditionalWrapper
      condition={withLayout}
      wrapper={(children) => (
        <LayoutWithoutCheckout>{children}</LayoutWithoutCheckout>
      )}
    >
      <Seo title="404: Not found" />
      <Info
        icon={<NoBikeMessageIcon />}
        title={
          <FormattedMessage
            id="failures.no-availability-title"
            defaultMessage="Not found"
          />
        }
        subtitles={[
          <FormattedMessage
            id="failures.no-availability-line1"
            defaultMessage="We couldn't find what you're looking for, please return to the homepage."
          />,
        ]}
      />
    </ConditionalWrapper>
  </>
);

export default NotFoundPage;
